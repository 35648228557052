
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Programs</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-sm-12 col-md-4">
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <b-button variant="primary" class="" @click.prevent="popupModalShow = true" type="button">
            <i class="ri ri-add-fill align-bottom"></i> <span class="d-inline"> Create New </span></b-button>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm"  align="middle">
              <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
                v-slot:[`head(${column.key})`] >
                  <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                  d-flex align-items-center">
                    {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                  </div>
              </template>
        
              <template v-slot:cell(friendly_id)="data">
                <div style="min-width:7rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.friendly_id }}
                </div>
              </template>
              <template v-slot:cell(program_title)="data">
                <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.program_title }}
                </div>
              </template>
              <template v-slot:cell(beneficiary_count)="data">
                <div style="min-width:5rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.beneficiary_count }}
                </div>
              </template>
              <template v-slot:cell(verification_count)="data">
                <div style="min-width:5rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.verification_count }}
                </div>
              </template>
              <template v-slot:cell(nin_verifications)="data">
                <div style="min-width:5rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.nin_verifications }}
                </div>
              </template>
              <template v-slot:cell(vin_verifications)="data">
                <div style="min-width:5rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.vin_verifications }}
                </div>
              </template>
              <template v-slot:cell(bvn_verifications)="data">
                <div style="min-width:5rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                  {{ data.item.bvn_verifications }}
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
      <b-modal v-model="popupModalShow" hide-footer centered
        :title="editMode? 'Update program':'Add program'"
        title-class="fs-6" @hide="initializeAll()">
        <program-form v-if="popupModalShow" @closeMe="initializeAll()"
         @addItem="fetchItems()" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';
import ProgramForm from "@/components/forms/ProgramForm.vue"

export default {
  name: "programs",
  components:{
    IsLoading,
    ProgramForm,
  },
  data() {
    return {
      isLoading: false,
      popupModalShow: false,
      searchQuery: "",
      pageOptions: [10, 25, 50, 100],
      columns: [
        {
          key: "program_id",
          label: "Program Id",
          sortable: true
        },
        {
          key: "program_title",
          label: "Title",
          sortable: true
        },
        {
          key: "beneficiary_count",
          label: "Beneficiaries",
          sortable: true
        }, 
        {
          key: "verification_count",
          label: "Total Verifications",
          sortable: true
        }, 
        {
          key: "nin_verifications",
          label: "NIN Verifications",
          sortable: true
        },    
        {
          key: "vin_verifications",
          label: "VIN Verifications",
          sortable: true
        },   
        {
          key: "bvn_verifications",
          label: "BVN Verifications",
          sortable: true
        },   
      ],
      sortBy: {
        sort_column: "vin",
        sort_direction: "asc"
      },
    }
  },
  watch: {
    defaultType (val) {
      this.fetchItems()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.program.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 25
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
    getSortingLabel(){
      return key => {
        let iconClass = 'text-muted ri-arrow-up-line';
        if(this.sortBy.sort_column != key) return `<span class='ms-2 ${iconClass}'></span>`;
        iconClass = this.sortBy.sort_direction == 'desc' ? 'ri-arrow-up-line' : 'ri-arrow-down-line';
        return `<span class='ms-2 ${iconClass}'></span>`
      }
    }
  },
  methods: {
    navigateTo(id){
      this.$router.push({path: `/programs/${id}`});
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.sortBy
      }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("program/fetchItems", payload)
      .then((_) => this.isLoading = false )
    },
    deleteItem(itemId) {
      this.confirmDelete({
        text: "You are about to delete a program",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/programs/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('program/DELETE_ITEM', itemId)
            }
          })
        }
      });
    },
    setSortOrder(column) {
      if (this.sortBy.sort_column === column.key) {
        this.sortBy.sort_direction = this.sortBy.sort_direction === 'desc' ? 'asc' : 'desc';
      } else {
        this.sortBy.sort_column = column.key;
        this.sortBy.sort_direction = 'desc';
      }
      this.fetchItems();
    },
    initializeAll(){
      this.popupModalShow = false;
    },
  },
  created(){
    this.fetchItems()
  },
}

</script>

